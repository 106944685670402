<script setup lang="ts">
const route = useRoute();
const { isHelpSlideoverOpen } = useDashboard();

const links = [
    {
        id: 'home',
        label: 'Home',
        icon: 'i-heroicons-home',
        to: '/',
        tooltip: {
            text: 'Home',
            shortcuts: ['G', 'H'],
        },
    },
    {
        id: 'dossiers',
        label: 'Dossiers',
        icon: 'i-heroicons-document-text-solid',
        to: '/dossiers',
    },
    {
        id: 'dashboard',
        label: 'Dashboard demo',
        icon: 'i-heroicons-presentation-chart-line',
        to: '/dashboard-demo',
    },
    {
        id: 'inbox',
        label: 'Inbox',
        icon: 'i-heroicons-inbox',
        to: '/inbox',
        badge: '4',
        tooltip: {
            text: 'Inbox',
            shortcuts: ['G', 'I'],
        },
    },
    {
        id: 'users',
        label: 'Users',
        icon: 'i-heroicons-user-group',
        to: '/users',
        tooltip: {
            text: 'Users',
            shortcuts: ['G', 'U'],
        },
    },
    {
        id: 'settings',
        label: 'Settings',
        to: '/settings',
        icon: 'i-heroicons-cog-8-tooth',
        children: [
            {
                label: 'General',
                to: '/settings',
                exact: true,
            },
            {
                label: 'Members',
                to: '/settings/members',
            },
            {
                label: 'Notifications',
                to: '/settings/notifications',
            },
        ],
        tooltip: {
            text: 'Settings',
            shortcuts: ['G', 'S'],
        },
    },
];

const footerLinks = [
    {
        label: 'Invite people',
        icon: 'i-heroicons-plus',
        to: '/settings/members',
    },
    {
        label: 'Help & Support',
        icon: 'i-heroicons-question-mark-circle',
        click: (): void => {
            isHelpSlideoverOpen.value = true;
        },
    },
];

const groups = [
    {
        key: 'links',
        label: 'Go to',
        commands: links.map(link => ({ ...link, shortcuts: link.tooltip?.shortcuts })),
    },
    {
        key: 'code',
        label: 'Code',
        commands: [
            {
                id: 'source',
                label: 'View page source',
                icon: 'i-simple-icons-github',
                click: (): void => {
                    window.open(
                        `https://github.com/nuxt-ui-pro/dashboard/blob/main/pages${route.path === '/' ? '/index' : route.path}.vue`,
                        '_blank'
                    );
                },
            },
        ],
    },
];
</script>

<template>
    <UDashboardLayout>
        <UDashboardPanel :width="250" :resizable="{ min: 200, max: 300 }" collapsible>
            <UDashboardNavbar class="!border-transparent" :ui="{ left: 'flex-1' }">
                <template #left>
                    <TeamsDropdown />
                </template>
            </UDashboardNavbar>

            <UDashboardSidebar>
                <template #header>
                    <UDashboardSearchButton />
                </template>

                <UDashboardSidebarLinks :links="links" />

                <!-- <UDivider /> -->

                <div class="flex-1" />

                <UDashboardSidebarLinks :links="footerLinks" />

                <UDivider class="sticky bottom-0" />

                <template #footer>
                    <!-- ~/components/UserDropdown.vue -->
                    <UserDropdown />
                </template>
            </UDashboardSidebar>
        </UDashboardPanel>

        <slot />

        <!-- ~/components/HelpSlideover.vue -->
        <HelpSlideover />
        <!-- ~/components/NotificationsSlideover.vue -->
        <NotificationsSlideover />

        <ClientOnly>
            <LazyUDashboardSearch :groups="groups" />
        </ClientOnly>
    </UDashboardLayout>
</template>
