<script setup lang="ts">
import lodashCompact from 'lodash-es/compact';

const { isHelpSlideoverOpen } = useDashboard();
const { isDashboardSearchModalOpen } = useUIState();
const { metaSymbol } = useShortcuts();
const { logout, data } = useAuthUtils();

const items = computed(() => [
    [
        {
            slot: 'account',
            label: '',
            disabled: true,
        },
    ],
    [
        {
            label: 'Settings',
            icon: 'i-heroicons-cog-8-tooth',
            to: '/settings',
        },
        {
            label: 'Command menu',
            icon: 'i-heroicons-command-line',
            shortcuts: [metaSymbol.value, 'K'],
            click: (): void => {
                isDashboardSearchModalOpen.value = true;
            },
        },
        {
            label: 'Help & Support',
            icon: 'i-heroicons-question-mark-circle',
            shortcuts: ['?'],
            click: (): void => {
                isHelpSlideoverOpen.value = true;
            },
        },
    ],
    [
        {
            label: 'Sign out',
            icon: 'i-heroicons-arrow-left-on-rectangle',
            click: logout,
        },
    ],
]);

const fullName = computed(() => lodashCompact([data.value?.firstname, data.value?.lastname]).join(' ') || undefined);
</script>

<template>
    <UDropdown
        mode="hover"
        :items="items"
        :ui="{ width: 'w-full', item: { disabled: 'cursor-text select-text' } }"
        :popper="{ strategy: 'absolute', placement: 'top' }"
        class="w-full"
    >
        <template #default="{ open }">
            <UButton color="gray" variant="ghost" class="w-full" :label="fullName" :class="[open && 'bg-gray-50 dark:bg-gray-800']">
                <template #leading>
                    <UAvatar icon="i-heroicons-user" size="2xs" />
                </template>

                <template #trailing>
                    <UIcon name="i-heroicons-ellipsis-vertical" class="w-5 h-5 ml-auto" />
                </template>
            </UButton>
        </template>

        <template #account>
            <div class="text-left">
                <p>Signed in as</p>
                <p class="truncate font-medium text-gray-900 dark:text-white">{{ data?.email }}</p>
            </div>
        </template>
    </UDropdown>
</template>
